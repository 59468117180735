import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContainerLibraryMenu from "../../components/equipment-library-menu"
import EquipmentDimensionTable from "../../components/equipment-dimension-table"
import SignupBanner from "../../components/signupBanner"
import { typeName, conversion, convert, convertVol, volUnit } from "../../util"
import { graphql } from "gatsby" // to query for image data

const ContainerPage = ({ data }) => {
  const hold = data.restApiApi2HoldsDefault.data
  const base_type = data.restApiApi2HoldsDefault.base_type
  const type_name = typeName(base_type)
  let [dimension, setDimension] = useState("MM")
  let [weightDimension, setWeightDimension] = useState("KG")
  useEffect(() => {
    setDimension(localStorage.getItem("dimension") || "MM")
    setWeightDimension(localStorage.getItem("weightDimension") || "KG")
  }, [])
  useEffect(() => {
    window.cargoPlannerSDK.SceneManager.init().then(() => {
      window.cargoPlannerSDK.SceneManager.createScene(
        [hold],
        "camera_only",
        1200,
        600,
        {
          containerMeasurements: { lengthDim: dimension },
          cogSettings: { hide: true },
          backgroundColor: "rgb(248, 250, 252)",
        }
      ).then(canvas => {
        canvas.style.maxWidth = "100%"
        canvas.style.height = "auto"
        canvas.style.margin = "0 auto"
        document.getElementById("scene").replaceChildren(canvas)
      })
    })
  }, [hold, dimension])

  const onDimensionChange = dim => {
    localStorage.setItem("dimension", dim)
    setDimension(dim)
  }

  const onWeightDimChange = dim => {
    localStorage.setItem("weightDimension", dim)
    setWeightDimension(dim)
  }
  const c = conversion(dimension)

  let volume =
    convertVol(dimension, hold.L) *
    convertVol(dimension, hold.W) *
    convertVol(dimension, hold.H || hold.max_height)

  // take contours into consideration for volume
  let contouredVolume =
    (convertVol(dimension, hold.contours?.front_top_contour_l) *
      convertVol(dimension, hold.contours?.front_top_contour_h) *
      hold.W || 0) /
      2 +
    (convertVol(dimension, hold.contours?.rear_top_contour_l) *
      convertVol(dimension, hold.contours?.rear_top_contour_h) *
      hold.W || 0) /
      2 +
    (convertVol(dimension, hold.contours?.side1_top_contour_l) *
      convertVol(dimension, hold.contours?.side1_top_contour_h) *
      hold.L || 0) /
      2 +
    (convertVol(dimension, hold.contours?.side2_top_contour_l) *
      convertVol(dimension, hold.contours?.side2_top_contour_h) *
      hold.L || 0) /
      2 +
    (convertVol(dimension, hold.contours?.front_bottom_contour_l) *
      convertVol(dimension, hold.contours?.front_bottom_contour_h) *
      hold.W || 0) /
      2 +
    (convertVol(dimension, hold.contours?.rear_bottom_contour_l) *
      convertVol(dimension, hold.contours?.rear_bottom_contour_h) *
      hold.W || 0) /
      2 +
    (convertVol(dimension, hold.contours?.side1_bottom_contour_l) *
      convertVol(dimension, hold.contours?.side1_bottom_contour_h) *
      hold.L || 0) /
      2 +
    (convertVol(dimension, hold.contours?.side2_bottom_contour_l) *
      convertVol(dimension, hold.contours?.side2_bottom_contour_h) *
      hold.L || 0) /
      2

  volume = Math.round(volume - contouredVolume)

  return (
    <Layout>
      <SEO title="Container Library" />
      <ContainerLibraryMenu
        selected={hold.id}
        onDimensionChange={onDimensionChange}
        onWeightDimChange={onWeightDimChange}
        weightDim={weightDimension}
        lengthDim={dimension}
      >
        <div className="container mx-auto pt-6 pb-16 px-6 mx-auto text-lg">
          <h1 className="my-4 text-4xl font-bold leading-snug tracking-wider">
            {hold.name}
          </h1>
          <div className="mb-8 space-y-4">
            {!!hold.description && <p>{hold.description}</p>}
            <p>
              The {!!hold.H && "inner"} size of a {hold.name} is {c(hold.L)} x{" "}
              {c(hold.W)} {hold.H ? `x ${c(hold.H)} (LxWxH)` : " (LxW)"}
              {!!hold.max_height &&
                ` and allows loading to a maximum height of ${c(
                  hold.max_height
                )}`}
              {(!!hold.max_height || !!hold.H) && (
                <span>
                  {" "}
                  which gives a total loading volume of {volume}&nbsp;
                  {volUnit(dimension)}
                  <sup>3</sup>
                </span>
              )}
              .{" "}
              {!!hold.door?.H && (
                <span>
                  The {type_name} has a door that's {c(hold.door.W)} x{" "}
                  {c(hold.door.H)} (WxH).{" "}
                </span>
              )}
            </p>
            <p>
              {(!!hold.tare || !!hold.payload) && (
                <span>
                  A {hold.name} has a default{" "}
                  {!!hold.tare && (
                    <span>
                      tare of {convert(weightDimension, hold.tare)} and a
                    </span>
                  )}{" "}
                  maximum payload of {convert(weightDimension, hold.payload)}.
                  Depending on your use case or specific {type_name} this might
                  vary and is easy to change.
                </span>
              )}
            </p>
            <p>
              Every aspect of the {type_name}, from lengths to{" "}
              {hold.axles
                ? "axle count & weights"
                : hold.contours
                ? "contours"
                : hold.door
                ? "door dimensions"
                : "payload & tare"}
              , is of course customizable in Cargo-Planner.
            </p>
          </div>
          <div
            id="scene"
            className="border rounded-lg"
            style={{
              maxWidth: "100%",
              cursor: "grab",
            }}
          ></div>
          <div
            className="flex flex-wrap justify-evenly"
            style={{ marginLeft: "-2rem" }}
          >
            <EquipmentDimensionTable
              title={((hold.H && "Inner ") || "") + "Dimensions"}
              values={[
                { title: "Length", value: hold.L },
                { title: "Width", value: hold.W },
                { title: "Height", value: hold.H },
                { title: "Max Height", value: hold.max_height },
                { title: "Floor Height", value: hold.floor_height },
              ]}
              lengthDim={dimension}
            />
            <EquipmentDimensionTable
              title={"Approx. Weights"}
              values={[
                { title: "Payload", value: hold.payload },
                { title: "Tare", value: hold.tare },
              ]}
              weightDim={weightDimension}
            />
            <EquipmentDimensionTable
              title="Door"
              values={[
                { title: "Height", value: hold.door?.H },
                { title: "Width", value: hold.door?.W },
              ]}
              lengthDim={dimension}
            />
            <EquipmentDimensionTable
              title="Axles"
              values={[
                {
                  title: hold.axles?.front_axle_no
                    ? "Front Axle"
                    : "Kingpin Position",
                  value: hold.axles?.front_axle_x,
                },
                { title: "Rear Axle", value: hold.axles?.rear_axle_x },
              ]}
              lengthDim={dimension}
            />
            <EquipmentDimensionTable
              title="Front Table"
              values={[
                {
                  title: "Length",
                  value: hold.tables?.front_table_l,
                },
                {
                  title: "Width",
                  value: hold.tables?.front_table_w,
                },
                {
                  title: "Height",
                  value: hold.tables?.front_table_h,
                },
              ]}
              lengthDim={dimension}
            />
            <EquipmentDimensionTable
              title="Rear Table"
              values={[
                {
                  title: "Length",
                  value: hold.tables?.rear_table_l,
                },
                {
                  title: "Width",
                  value: hold.tables?.rear_table_w,
                },
                {
                  title: "Height",
                  value: hold.tables?.rear_table_h,
                },
              ]}
              lengthDim={dimension}
            />
            <EquipmentDimensionTable
              title="Top Contours"
              lengthDim={dimension}
              values={[
                {
                  title: "Left Length",
                  value: hold.contours?.front_top_contour_l,
                },
                {
                  title: "Left Height",
                  value: hold.contours?.front_top_contour_h,
                },
                {
                  title: "Right Length",
                  value: hold.contours?.rear_top_contour_l,
                },
                {
                  title: "Right Height",
                  value: hold.contours?.rear_top_contour_h,
                },
                {
                  title: "Front Length",
                  value: hold.contours?.side1_top_contour_l,
                },
                {
                  title: "Front Height",
                  value: hold.contours?.side1_top_contour_h,
                },
                {
                  title: "Rear Length",
                  value: hold.contours?.side2_top_contour_l,
                },
                {
                  title: "Rear Height",
                  value: hold.contours?.side2_top_contour_h,
                },
              ]}
            />
            <EquipmentDimensionTable
              title="Bottom Contours"
              lengthDim={dimension}
              values={[
                {
                  title: "Left Length",
                  value: hold.contours?.front_bottom_contour_l,
                },
                {
                  title: "Left Height",
                  value: hold.contours?.front_bottom_contour_h,
                },
                {
                  title: "Right Length",
                  value: hold.contours?.rear_bottom_contour_l,
                },
                {
                  title: "Right Height",
                  value: hold.contours?.rear_bottom_contour_h,
                },
                {
                  title: "Front Length",
                  value: hold.contours?.side1_bottom_contour_l,
                },
                {
                  title: "Front Height",
                  value: hold.contours?.side1_bottom_contour_h,
                },
                {
                  title: "Rear Length",
                  value: hold.contours?.side2_bottom_contour_l,
                },
                {
                  title: "Rear Height",
                  value: hold.contours?.side2_bottom_contour_h,
                },
              ]}
            />
          </div>
        </div>
        <div className="bg-white py-16">
          <h3 className="text-3xl text-center mt-16 leading-relaxed">
            Ship less air. Waste less time.
            <br />
            Let Cargo-Planner calculate how to fill your {hold.name}.
          </h3>
          <SignupBanner />
        </div>
      </ContainerLibraryMenu>
    </Layout>
  )
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query($id: String) {
    restApiApi2HoldsDefault(id: { eq: $id }) {
      name
      id
      base_type
      data {
        id
        name
        description
        H
        L
        W
        door {
          W
          H
        }
        floor_height
        items {
          H
          L
          W
          WT
          qty
          color
          pos {
            x
            y
            z
          }
        }
        max_height
        no_end_walls
        no_roof
        no_side_walls
        clearence
        axles {
          front_axle_no
          front_axle_spacing
          front_axle_x
          rear_axle_no
          rear_axle_spacing
          rear_axle_x
        }
        bottom_frame {
          end_frame_h
          end_frame_w
          side_frame_h
          side_frame_w
        }
        posts {
          front_post_h
          front_post_l
          front_post_w
          rear_post_h
          rear_post_l
          rear_post_w
        }
        tables {
          deck_l
          deck_z
          front_table_h
          front_table_l
          front_table_w
          rear_table_h
          rear_table_l
          rear_table_w
        }
        top_frame {
          end_frame_h
          end_frame_w
          side_frame_h
          side_frame_w
        }
        contours {
          auto_bottom_spacer_h
          bottom_spacer_h
          front_bottom_contour_h
          front_bottom_contour_l
          front_top_contour_h
          front_top_contour_l
          rear_bottom_contour_h
          rear_top_contour_h
          rear_bottom_contour_l
          rear_top_contour_l
          side1_bottom_contour_h
          side1_bottom_contour_l
          side1_top_contour_h
          side1_top_contour_l
          side2_bottom_contour_h
          side2_bottom_contour_l
          side2_top_contour_h
          side2_top_contour_l
        }
        walls {
          transverse {
            X
          }
        }
        payload
        tare
      }
    }
  }
`

export default ContainerPage
